import { Component, OnInit } from '@angular/core';
import { ModalController, AlertController } from '@ionic/angular';
import { DataService } from '../service/data.service';
import { Router, NavigationExtras } from '@angular/router';


@Component({
  selector: 'app-comments-modal',
  templateUrl: './comments-modal.page.html',
  styleUrls: ['./comments-modal.page.scss'],
})
export class CommentsModalPage implements OnInit {

  msgList;
  sendingList;
  showSendingList:boolean = false;
  actvId;
  showMsg='';

  constructor(
    public modalCtrl: ModalController,
    public service: DataService,
    public router: Router,
    public alert: AlertController
  ) { }

  ngOnInit() {
    this.showMsg = 'Please wait...';
    this.service.fetchMsgListing().subscribe((res)=>{
      const resArray = Object.keys(res).map(i => res[i]);
      console.log(resArray)
      if(resArray.length > 1 && resArray[0].success == 'true'){
        if(resArray[0].comments && resArray[0].comments.length > 0){
          this.msgList = resArray[0].comments;
        }else{
          this.showMsg = 'No message found';
        }
      }else{
        this.showMsg = resArray[0].errMsg;
        /* this.service.presentToast(resArray[0].errMsg);
        console.log(resArray[0].errMsg); */
      }
    }, error =>{
      this.showMsg = 'Sorry! some error happend';
      //this.service.presentToast('Sorry!, Network or Server releted error');
      console.log("Error happened " + JSON.stringify(error));
    })
  }

  showRcpntList(data, id){
    this.actvId = id;
    if(!this.showSendingList){
      this.showSendingList = true;
      this.sendingList = data;
      console.log(this.sendingList);
    }else{
      console.log('data')
      this.showSendingList = false;
    }
  }

  chatboxDetail(m) {
    this.modalCtrl.dismiss();
    let navigationExtras: NavigationExtras = {
      state: {
        msg: m
      }
    };
    this.router.navigate(['/chatbox-details'], navigationExtras);
  }

  async deleteAlert(id) {
    const alert = await this.alert.create({
      header: 'Delete Post!',
      message: 'Do you want to delete the post?',
      buttons: [
        {
          text: 'NO',
          role: 'cancel',
          cssClass: 'secondary',
        }, {
          text: 'YES',
          handler: () => {
            this.delete(id)
          }
        }
      ]
    });
    await alert.present();
  }
  
  delete(id){
    this.service.commentDelete(id).subscribe((res)=>{
      console.log(res)
      const resArray = Object.keys(res).map(i => res[i]);
      if(resArray.length > 1 && resArray[0].success == 'true'){
        this.service.presentToast(resArray[0].msg);
        this.ngOnInit();
      }else{
        this.service.presentToast(resArray[0].errMsg);
        console.log(resArray[0].errMsg);
      }
    }, error =>{
      this.service.presentToast('Sorry!, Network or Server releted error');
      console.log("Error happened " + JSON.stringify(error));
    })
  }

}

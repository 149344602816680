import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';

import { Camera } from '@ionic-native/camera/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { File } from '@ionic-native/file/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { CommentPageModule } from './comment/comment.module';
import { FilePath } from '@ionic-native/file-path/ngx';
import { SharePopupPageModule } from './share-popup/share-popup.module';
import { NewMessageAttachmentPageModule } from './new-message-attachment/new-message-attachment.module';
import { PhotoViewerPageModule } from './photo-viewer/photo-viewer.module';
import { CommentsModalPageModule } from './comments-modal/comments-modal.module';

import { IonicStorageModule } from '@ionic/storage';
import { GrouplistPageModule } from './grouplist/grouplist.module';
import { FCM } from '@ionic-native/fcm/ngx';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    //IonicModule.forRoot({hardwareBackButton: false}),
    AppRoutingModule,
    HttpClientModule,
    CommentPageModule,
    SharePopupPageModule,
    NewMessageAttachmentPageModule,
    PhotoViewerPageModule,
    CommentsModalPageModule,
    GrouplistPageModule,
    IonicStorageModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    Camera,
    WebView,
    FilePath,
    FCM,
    File,
    FileTransfer,
    FileOpener,
    AndroidPermissions
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

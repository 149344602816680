import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-grouplist',
  templateUrl: './grouplist.page.html',
  styleUrls: ['./grouplist.page.scss'],
})
export class GrouplistPage implements OnInit {

  groups;

  constructor() { }

  ngOnInit() {
    console.log(this.groups)
  }

}

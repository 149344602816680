import { Component, OnInit } from '@angular/core';
import { DataService } from '../service/data.service';
import { ModalController } from '@ionic/angular';


@Component({
  selector: 'app-comment',
  templateUrl: './comment.page.html',
  styleUrls: ['./comment.page.scss'],
})
export class CommentPage implements OnInit {

  postId;
  name;
  comments;
  commentsList;
  noOfCmtMade:number=0;
  showMsg = '';

  constructor(
    public service: DataService,
    public modalCtrl: ModalController,
  ) {
  }

  ngOnInit() {
    //console.log(`${this.postId}`);
    this.showMsg = 'Please wail...'
    this.service.postReply(`${this.postId}`).subscribe((res)=>{
      console.log(res);
      const resArray = Object.keys(res).map(i => res[i]);
      if(resArray.length > 1 && resArray[0].success == 'true'){
        this.commentsList = resArray[0].comments;
        if(this.commentsList){
          this.showMsg = '';
        }else{
          this.showMsg = 'Be the first to comment';
        }
        //this.commentsList = true;
        //console.log(this.commentsList)
      }else{
        this.showMsg = resArray[0].errMsg;
        //this.service.presentToast(resArray[0].errMsg);
        console.log(resArray[0].errMsg);
      }
    })
  }

  post_comment(){
    if(this.comments){
      this.service.makeComments(`${this.postId}`, this.comments).subscribe((res)=>{
        //console.log(res);
        const resArray = Object.keys(res).map(i => res[i]);
        //console.log(resArray)
        if(resArray.length > 1 && resArray[0].success == 'true'){
          this.service.presentToast(resArray[0].msg);
          //console.log(resArray[0].msg);
          this.noOfCmtMade+=1;
          this.comments = '';
          this.ngOnInit();
        }else{
          this.service.presentToast(resArray[0].errMsg);
          console.log(resArray[0].errMsg);
        }      
      });
    }else{
      this.service.presentToast("Type some comment first");
    }
  }

  modalDismiss(){
    this.modalCtrl.dismiss(this.noOfCmtMade);
  }

}

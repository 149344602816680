import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { DomSanitizer } from '@angular/platform-browser';
import { FileTransfer, FileUploadOptions, FileTransferObject } from '@ionic-native/file-transfer/ngx';
import { File } from '@ionic-native/file/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { DataService } from '../service/data.service';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';

@Component({
  selector: 'app-photo-viewer',
  templateUrl: './photo-viewer.page.html',
  styleUrls: ['./photo-viewer.page.scss'],
})
export class PhotoViewerPage implements OnInit {
name:any;
  imgs;
  act_index;
  fileTransfer: FileTransferObject;

  constructor(
    public modalCtrl: ModalController,
    public sanitizer: DomSanitizer,
    public transfer: FileTransfer,
    public file: File,
    public fileOpener: FileOpener,
    public alertCtrl: AlertController,
    public service: DataService,
    private androidPermissions: AndroidPermissions
  ) {
    // this.fileTransfer = this.transfer.create();
  }


  ngOnInit() {
    var slides = document.querySelector('ion-slides');
    slides.slideTo(this.act_index);
    if(this.imgs){
      for(let i of this.imgs){
        i.trustedpdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(i.img_url);
      }
    }
  }

  modalDismiss(){
    this.modalCtrl.dismiss();
  }

  download(url) {
    this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE).then(
      result => {
        if(result.hasPermission){
          this.file.checkDir(this.file.externalRootDirectory, 'Download').then(_ => {
            this.downloadFile(url);
          }).catch(err => {
            this.file.createDir(this.file.externalRootDirectory, 'Download', false).then(response => {
              this.downloadFile(url);
            }).catch(err => {
              console.log('Could not create directory',err);
              this.service.presentToast('Sorry! could not create directory');
            });
          });
        }else{
          this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.WRITE_EXTERNAL_STORAGE).then(
            result => {  
              this.file.checkDir(this.file.externalRootDirectory, 'Download').then(_ => {
                this.downloadFile(url);
              }).catch(err => {
                this.file.createDir(this.file.externalRootDirectory, 'Download', false).then(response => {
                  this.downloadFile(url);
                }).catch(err => {
                  console.log('Could not create directory',err);
                  this.service.presentToast('Sorry! could not create directory');
                });
              });
            },
            err => {
              this.service.presentToast('Sorry! error in storage permission checking');
            }
          );
        }

        
      },
      err => {
        
      }
    );
  }

  downloadFile(url){
    let fileTransfer: FileTransferObject = this.transfer.create();
    fileTransfer.download(url, this.file.externalRootDirectory + 'Download/' + url.split("/").pop()).then((entry) => {
      console.log('file download response',entry);
      let fileExtn = entry.name.split('.').reverse()[0];
      let fileMIMEType = this.getMIMEtype(fileExtn);
      console.log(fileExtn, fileMIMEType);
      this.presentAlertConfirm(entry.toURL(), fileMIMEType);
    }).catch((err) =>{
      console.log('error in file download',err);
      this.service.presentToast('Sorry! could not download the file');
    });
  }

  async presentAlertConfirm(path, type) {
    const alert = await this.alertCtrl.create({
      cssClass: 'my-custom-class',
      header: 'Confirm!',
      message: 'File successfully downloaded.',
      buttons: [
        {
          text: 'OKAY',
          role: 'cancel',
          cssClass: 'secondary'
        }, {
          text: 'OPEN',
          handler: () => {
            this.fileOpener.open(path, type)
            .then(() => console.log('File is opened'))
            .catch(e => console.log('Error opening file', e));
          }
        }
      ]
    });

    await alert.present();
  }

  getMIMEtype(extn){
    let ext=extn.toLowerCase();
    let MIMETypes={
      'txt' :'text/plain',
      'docx':'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'doc' : 'application/msword',
      'pdf' : 'application/pdf',
      'jpg' : 'image/jpeg',
      'jpeg': 'image/jpeg',
      'bmp' : 'image/bmp',
      'png' : 'image/png',
      'xls' : 'application/vnd.ms-excel',
      'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'rtf' : 'application/rtf',
      'ppt' : 'application/vnd.ms-powerpoint',
      'pptx': 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
    }
    return MIMETypes[ext];
  }

  redirect(url:any){
    console.log(123);
    window.location.href = url;
  }

}

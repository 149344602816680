import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';
import { ToastController, LoadingController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class DataService implements OnInit{

  //dataUrl = "https://estudy.sparkscodes.com/api/";
  dataUrl = "https://kvaschool.sparkscodes.com/api/";
  // dataUrl = "https://schoolapp.sparkscodes.com/api/";

  userToken;
  userType;
  userName;
  userId;
  school;
  logo;

    
  constructor(
    public http: HttpClient,
    public toast: ToastController,
    private storage: Storage,
    public loading: LoadingController,
    public router:Router,
  ) {
    this.ngOnInit();
  }

  ngOnInit(){
    this.storage.get('KVA_user').then((val)=>{
      if(val){
        console.log(val);
        this.userToken = val.token;
        this.userType = val.user_type;
        this.userName = val.name;
        this.userId = val.userId;
        this.school = val.school_name;
        this.logo = val.logo;
      }else{
        this.userToken = null;
        this.userType = null;
        this.userName = null;
        this.userId = null;
      }
    })
  }

  postMethod(route, postData){
    return this.http.post(this.dataUrl+route,postData,{headers:{
      'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
    }}).map(res => res);
  }

  deviceID(t, ut){
    let postData = {"data":{"device_id" : t, "user_id":this.userId, "token": ''}};
    return this.http.post(this.dataUrl+'getdeviceid?token='+ut, postData, {headers:{
      'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
    }}).map(res => res);
  }
  
  versionCheck(v){
    let postData = {"data":{"version" : v}};
    return this.http.post(this.dataUrl+'version-check',postData,{headers:{
      'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
    }}).map(res => res);
  }

  /* back_versionCheck(v){
    let postData = {"data":{"version" : v}};
    return new Promise(resolve => {
      this.http.post(this.dataUrl+'version-check', postData).subscribe(res => {
        resolve(res);
        //console.log(res);
      }, err => {
        this.dismissLoading();
        this.presentToast('Sorry!, Network or Server releted error');
        console.log(err);
      });
    });
  } */
  
  loginService(uname, pass){
    let postData = {"data":{"token":"abcd", "username": uname, "password": pass}};
    return this.http.post(this.dataUrl+'login', postData, {headers:{
      'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
    }}).map(res => res);
  }

  fetchPostsListing(postData){
    return this.http.post(this.dataUrl+'comment-list?token='+this.userToken, postData, {headers:{
      'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
    }}).map(res => res);
  }

  createPost(type, msg, sub, imgs, sendto, ctype){
    let postData = {
      data: {
        "token": this.userToken,
        "post_type": type,
        "text_message": msg,
        "image": imgs,
        "subject_title": sub,
        "send_to": sendto ? sendto : '',
        "comment_type": ctype
      }
    }
    //alert(JSON.stringify(postData));
    console.log(postData);
    return this.http.post(this.dataUrl+'compose-comment?token='+this.userToken, postData, {headers:{
      'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
    }}).map(res => res);
  }

  editPost(id,title,msg){
    let postData = {
      data: {"subject_title":title,"text_message":msg,"id":id}
    }
    //alert(JSON.stringify(postData));
    console.log(postData);
    return this.http.post(this.dataUrl+'edit-message?token='+this.userToken, postData, {headers:{
      'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
    }}).map(res => res);
  }

  fetchGroupList(){
    let postData = {"data":{"token":this.userToken}};
    return this.http.post(this.dataUrl+'group-list?token='+this.userToken, postData, {headers:{
      'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
    }}).map(res => res);
  }

  postLike(id, liked){
    let postData = {"data":{"post_liked":liked,'parent_comment_id':id}}
    return this.http.post(this.dataUrl+'comment-like?token='+this.userToken, postData, {headers:{
      'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
    }}).map(res => res);
  }

  postReply(id){
    let postData = {"data":{"comment_id":id}}
    return this.http.post(this.dataUrl+'reply-list?token='+this.userToken, postData, {headers:{
      'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
    }}).map(res => res);
  }

  makeComments(id, msg){
    let postData = {"data":{"text_message":msg,'parent_comment_id':id}}
    return this.http.post(this.dataUrl+'reply-comment?token='+this.userToken, postData, {headers:{
      'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
    }}).map(res => res);
  }

  sharePost(id, sendto){
    let postData = {"data":{"token":this.userToken, "parent_comment_id":id, "send_to": sendto ? sendto : ''}}
    console.log(postData)
    return this.http.post(this.dataUrl+'comment-share?token='+this.userToken, postData, {headers:{
      'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
    }}).map(res => res);
  }

  fetchMsgListing(){
    let postData = {"data":{"token":this.userToken}};
    return this.http.post(this.dataUrl+'send-message-list?token='+this.userToken, postData, {headers:{
      'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
    }}).map(res => res);
  }

  fetchReceivedMsgListing(){
    let postData = {"data":{"token":this.userToken, "post_type":'message-list'}};
    return this.http.post(this.dataUrl+'message-list?token='+this.userToken, postData, {headers:{
      'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
    }}).map(res => res);
  }

  fetchUserList(){
    let postData = {"data":{"token":this.userToken, "post_type": 'message'}};
    return this.http.post(this.dataUrl+'user-list?token='+this.userToken, postData, {headers:{
      'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
    }}).map(res => res);
  }

  profileDetails(){
    let postData = {"data":{"token":this.userToken}};
    return this.http.post(this.dataUrl+'profile-details?token='+this.userToken, postData, {headers:{
      'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
    }}).map(res => res);
  }

  profileUpdate(newName){
    let postData = {"data":{"userId":this.userId, "name":newName, "dob":""}};
    return this.http.post(this.dataUrl+'update-profile?token='+this.userToken, postData, {headers:{
      'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
    }}).map(res => res);
  }

  profilePicUpdate(img){
    let postData = {"data":{"userId":this.userId, "img":img}};
    return this.http.post(this.dataUrl+'update-profile-image?token='+this.userToken, postData, {headers:{
      'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
    }}).map(res => res);
  }

  commentDelete(id){
    let postData = {"data":{"comment_id":id}};
    return this.http.post(this.dataUrl+'delete-comment?token='+this.userToken, postData, {headers:{
      'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
    }}).map(res => res);
  }

  logout(){
    let postData = {"data":{"token":this.userToken}};
    return this.http.post(this.dataUrl+'logout?token='+this.userToken, postData, {headers:{
      'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8'
    }}).map(res => res);
  }
  
  async presentLoading() {
    const loading = await this.loading.create({
      spinner: "bubbles",
      cssClass: 'custom-class custom-loading'
    });
    return await loading.present();
  }

  async dismissLoading() {
    return await this.loading.dismiss();
  }

  async presentToast(msg){
    const toast = await this.toast.create({
      message: msg,
      duration: 2000
    });
    toast.present();
  }

}

import { Component } from '@angular/core';
import { Platform, NavController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { DataService } from './service/data.service';
import { Storage } from '@ionic/storage';
import { Router, NavigationStart } from '@angular/router';
import { FCM } from '@ionic-native/fcm/ngx';
import { Location } from '@angular/common';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  public appPages = [
    {
      title: 'Home',
      url: '/home',
      icon: 'home'
    },
    {
      title: 'List',
      url: '/list',
      icon: 'list'
    }
  ];

  lastTimeBackPress = 0;
  timePeriodToExit = 2000;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public dataService:DataService,
    public storage: Storage,
    public router:Router,
    public fcm: FCM,
    public nav: NavController,
    public location: Location,
    public modalCtrl: ModalController
  ) {
    this.initializeApp();
    this.storage.get('KVA_user').then((val)=>{
      if(val){
        this.router.navigateByUrl('/home');
      }else{
        this.router.navigateByUrl('/login');
      }
    });
  }

  async dismiss(){
    const element = await this.modalCtrl.getTop();
    if (element) {
      element.dismiss();
      return;
    }else{
      if (this.router.url == "/home" || this.router.url == "/login") {
        if (new Date().getTime() - this.lastTimeBackPress < this.timePeriodToExit) {
          //this.platform.exitApp();
          navigator['app'].exitApp();
        } else {
          this.dataService.presentToast('Press back again to exit App');
          this.lastTimeBackPress = new Date().getTime();
        }
      } else {
        this.location.back();
      }
    }
  }

  initializeApp() {
  
    this.platform.ready().then(() => {
    
      /* this.dataService.versionCheck("1.0").subscribe((res:any) => {
        console.log(res);
      }, error => {
        console.log("Error happened " + error)
      }); */

      document.addEventListener("backbutton", ()=> {
        this.dismiss();
      }, false);

      if(this.platform.is("cordova")){
        this.fcm.onNotification().subscribe(data => {
          console.log(data);
          if (data.wasTapped) {
            console.log('Received in background');
            // alert('Received in background');
            // this.router.navigate([data.landing_page, {val:data.price}]);
          } else {
            //console.log('Received in foreground');
            this.dataService.presentToast(data.body);
            // alert('Received in foreground');
            // this.router.navigate(['/compose', {val:data.price}]);
          }
        });
      }

      // this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.statusBar.backgroundColorByName('black');

      if(!navigator.onLine){
        this.dataService.presentToast('Please switch on your network')
      }

      window.addEventListener('offline', () => {
        this.dataService.presentToast('You are offline, Please check your network')
      });
      window.addEventListener('online', () => {
        window.location.reload();        
      });
    });
  }

  logout(){
    this.dataService.logout().subscribe((res:any) => {
      if(res.data && res.data.success == 'true'){
        this.storage.remove('KVA_user').then(()=>{
          this.dataService.ngOnInit();
          this.storage.remove('KVA_user');
          this.dataService.presentToast(res.data.msg);
          this.router.navigateByUrl('/login');
        }, error =>{
          this.dataService.presentToast('Sorry!, Error in clearing the storage');
          console.log(error)
        })
      }else{
        this.dataService.presentToast(res.errNode.errMsg);
      }
    }, error => {
      this.dataService.presentToast('Sorry!, Network or Server releted error');
      console.log("Error happened " + error)
    });
  }
}

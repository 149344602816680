import { Component, OnInit, HostListener } from '@angular/core';
import { DataService } from '../service/data.service';
import { Storage } from '@ionic/storage';
import { ModalController } from '@ionic/angular';


@Component({
  selector: 'app-share-popup',
  templateUrl: './share-popup.page.html',
  styleUrls: ['./share-popup.page.scss'],
})


export class SharePopupPage implements OnInit {

  groupList;
  masterCheck:boolean;
  selected_groups = [];
  group = {};
  ListType;
  searchBar;
  initialiseSearch;
  showMsg = '';
  
  constructor(
    private service: DataService,
    private storage: Storage,
    public modalCtrl: ModalController
  ){}

  // @HostListener('document:ionBackButton', ['$event'])
  // private async overrideHardwareBackAction($event: any) {
  // await this.modalCtrl.dismiss();
  // }

  ngOnInit() {}
  
  ionViewDidEnter() {
    //console.log(this.ListType)
    if(this.ListType && this.ListType == 'user'){
      this.searchBar = true;
      this.showMsg = 'Please wait...';
      this.service.fetchUserList().subscribe((res:any)=>{
        if(res.data){
          console.log(res.data);
          this.initialiseSearch = res.data;
          this.groupList = res.data;
        }else{
          this.showMsg = 'No data found';
        }
      }, error =>{
        this.showMsg = 'Sorry! some error happend';
      })
    }else{
      this.showMsg = 'Please wait...';
      this.service.fetchGroupList().subscribe((res:any)=>{
        console.log(res);
        if(res.data){
          this.groupList = res.data;
        }else{
          this.showMsg = 'No data found';
        }
      }, error => {
        this.showMsg = 'Sorry! some error happend';
        this.service.presentToast('Sorry!, Network or Server releted error');
        console.log("Error happened " + JSON.stringify(error));
      })
    }
  }

  getItems(ev: any) {
    this.groupList = this.initialiseSearch;
    const val = ev.target.value;
    if (val && val.trim() != '') {
      this.groupList = this.groupList.filter((item) => {
        return (item.name.toLowerCase().indexOf(val.toLowerCase()) > -1);
      })
    }
  }
  
  checkMaster(e) {
    setTimeout(()=>{
      this.groupList.forEach(obj => {
        obj.isChecked = this.masterCheck;
      });
    });
  }

  async selectedGroups(){
    this.groupList.forEach(obj => {
      if(obj.isChecked){
        let object = Object.create(this.group);
        object.id = obj.id,
        object.name = obj.name
        this.selected_groups.push(object);
        //console.log(this.selected_groups);
      }
    });
    await this.modalCtrl.dismiss(this.selected_groups);
  }

  async cancelModal(){
    await this.modalCtrl.dismiss();
  }

}

import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: './home/home.module#HomePageModule'
  },
  {
    path: 'list',
    loadChildren: './list/list.module#ListPageModule'
  },
  { path: 'compose', loadChildren: './compose/compose.module#ComposePageModule' },
  { path: 'comment', loadChildren: './comment/comment.module#CommentPageModule' },
  { path: 'message', loadChildren: './message/message.module#MessagePageModule' },
  { path: 'share-popup', loadChildren: './share-popup/share-popup.module#SharePopupPageModule' },
  { path: 'profile', loadChildren: './profile/profile.module#ProfilePageModule' },
  { path: 'login', loadChildren: './login/login.module#LoginPageModule' },
  { path: 'chatbox-details', loadChildren: './chatbox-details/chatbox-details.module#ChatboxDetailsPageModule' },
  { path: 'new-message', loadChildren: './new-message/new-message.module#NewMessagePageModule' },
  { path: 'new-message-attachment', loadChildren: './new-message-attachment/new-message-attachment.module#NewMessageAttachmentPageModule' },
  { path: 'photo-viewer', loadChildren: './photo-viewer/photo-viewer.module#PhotoViewerPageModule' },
  { path: 'comments-modal', loadChildren: './comments-modal/comments-modal.module#CommentsModalPageModule' },
  { path: 'grouplist', loadChildren: './grouplist/grouplist.module#GrouplistPageModule' },
  { path: 'register', loadChildren: './register/register.module#RegisterPageModule' }




];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
